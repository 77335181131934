export default {
  //ACTIONS
  GET_SITE_WORK_ACTION: 'GET_SITE_WORK_ACTION',
  GET_SITE_RULE_ACTION: 'GET_SITE_RULE_ACTION',
  GET_SITE_DOC_ACTION: 'GET_SITE_DOC_ACTION',
  GET_SITE_BURGER_ACTION: 'GET_SITE_BURGER_ACTION',
  //MUTATIONS
  CURRENT_BURGER_PAGE_SET: 'CURRENT_BURGER_PAGE_SET',
  TOGGLE_PANEL_BURGER: 'TOGGLE_PANEL_BURGER',
  SET_SITE_WORK: 'SET_SITE_WORK',
  SET_SITE_RULE: 'SET_SITE_RULE',
  SET_SITE_BURGER: 'SET_SITE_BURGER'
}
