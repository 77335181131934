import Constants from '@/helpers/constants'

export default {
  loading: false,
  hostname: 'localhost',
  // hostname: null,
  protocol: null,
  pathname: null,
  code: null,
  // code: Constants.APP_CODES.BUY,
  // code: Constants.APP_CODES.SUBSCRIPTION,
  // code: Constants.APP_CODES.BALANCE,
  // code: Constants.APP_CODES.BASKET,
  // code: 'ПодпискаДизайн',
  config: {
    panelWidth: 448
  },
  pageOrderConfig: null, // Настройки страницы "Заказ"
  pageContactConfig: null, // Настройки страницы "Контакты"
  pageBasketConfig: null, // Настройки страницы "Корзина"
  pagePaymentStatusConfig: null, // Настройки страницы "Статус оплаты",
  pageConfirmConfig: null, // Настройки страницы "Подтверждение",
  isMobile: false,
  showBtnBasket: true,
  showBtnBurger: true,
  offsetBottom: 0,
  opacity: 0,
  backUrl: null,
  isDesktop: false
}
