import Vue from 'vue'
import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import AuthService from './AuthService'

const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {}
})

Axios.interceptors.request.use(
  config => {
    //TODO - удалить это условие для проверки refresh_token
    // if (config.url !== 'api/site/GetCampaigns')
    config.headers.Authorization = 'Bearer ' + Vue.auth.getAccessToken()
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

createAuthRefreshInterceptor(Axios, async () => {
  await AuthService.authManagerForce()
})

export default Axios
