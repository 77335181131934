// import { START_PAGE, BASKET_PAGE } from '../../helpers/const/widgetPage'

export default {
  isInitCodeInProccess: false,
  verificationCode: null,
  contacts: {
    email: null,
    phone: null,
    sendingMethod: null
  },
  code: null, // тестовый пин код, код подтверждения,
  isEditContactMode: false
}
