<template>
  <div class="pb">
    <MlLoading v-if="loading" />
    <div class="row" v-else>
      <div class="col-12">
        <div class="text3">Отправитель сертификата</div>
      </div>
      <div class="col-12 d-flex align-center">
        <img src="@/assets/img/stepper.png" />
        <div class="flex-grow-1 d-flex flex-column pl-6">
          <div
            class="mb-5 text5"
            v-for="(item, index) in worksForSender"
            :key="index"
          >
            {{ item.TextStep }}
          </div>
        </div>
      </div>
      <div class="col-12 pt-10">
        <div class="text3">Получатель сертификата</div>
      </div>
      <div class="col-12 d-flex align-center">
        <img src="@/assets/img/stepper.png" />
        <div class="flex-grow-1 d-flex flex-column pl-6">
          <div
            class="mb-5 text5"
            v-for="(item, index) in worksForReciever"
            :key="index"
          >
            {{ item.TextStep }}
          </div>
          <!-- <div class="mb-6 text5">
            Переходит по ссылке, принимает поздравление и сохраняет сертификат
          </div>
          <div class="text5">
            Выбирает магазин, использует сертификат для оплаты всей или части
            покупки
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import panelBurgerTypes from '@/store/panelBurger/types'
import { mapActions, mapState } from 'vuex'

const sortFunc = (a, b) => {
  if (a?.Step > b?.Step) return 1
  if (a?.Step < b?.Step) return -1
  if (a?.Step === b?.Step) return 0
}

export default {
  name: 'HowItsWork',
  data: () => ({
    loading: false
  }),
  mounted() {
    this.setInitialize()
  },
  computed: {
    ...mapState({
      works: state => state?.panelBurger?.pageWorks
    }),
    worksForSender() {
      return this.works?.filter(x => x?.SertCardOwner).sort(sortFunc)
    },
    worksForReciever() {
      return this.works?.filter(x => !x?.SertCardOwner).sort(sortFunc)
    }
  },
  methods: {
    ...mapActions('panelBurger', [panelBurgerTypes.GET_SITE_WORK_ACTION]),
    async setInitialize() {
      this.loading = true
      try {
        await this[panelBurgerTypes.GET_SITE_WORK_ACTION]()
        this.loading = false
      } catch (e) {
        console.log('error', e)
      }
    }
  }
}
</script>
