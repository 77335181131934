import types from './types'

export default {
  [types.SET_CONFIRM_PAGE_CONFIG]: (state, payload) => {
    delete payload?.ErrorCode
    delete payload?.Message
    state.pageConfirmConfig = payload
  },
  [types.SET_STATUS_PAYMENT_PAGE_CONFIG]: (state, payload) => {
    delete payload?.ErrorCode
    delete payload?.Message
    state.pagePaymentStatusConfig = payload
  },
  [types.SET_APP_HOSTNAME]: (state, hostname) => {
    state.hostname = hostname
  },
  [types.SET_APP_PROTOCOL]: (state, protocol) => {
    state.protocol = protocol
  },
  [types.SET_APP_PATHNAME]: (state, pathname) => {
    state.pathname = pathname
  },
  [types.SET_BASKET_PAGE_CONFIG]: (state, payload) => {
    delete payload?.ErrorCode
    delete payload?.Message
    state.pageBasketConfig = payload
  },
  [types.SET_ORDER_PAGE_CONFIG]: (state, payload) => {
    delete payload?.ErrorCode
    delete payload?.Message
    // console.log('SET_ORDER_PAGE_CONFIG', payload)
    state.pageOrderConfig = payload
  },
  [types.SET_CONTACT_PAGE_CONFIG]: (state, payload) => {
    delete payload?.ErrorCode
    delete payload?.Message
    // console.log('SET_CONTACT_PAGE_CONFIG', payload)
    state.pageContactConfig = payload
  },
  [types.SET_OPACITY]: (state, value) => {
    state.opacity = value
  },
  [types.SET_BOTTOM_OFFSET]: (state, value) => {
    state.offsetBottom = value
  },
  [types.SET_TYPE_BROWSER]: (state, isMobile) => {
    state.isMobile = isMobile
  },
  [types.SET_TYPE_DESKTOP]: (state, isDesktop) => {
    state.isDesktop = isDesktop
  },
  [types.TEMPLATE_CODE_SET]: (state, code) => {
    state.code = code
  },
  [types.SET_LOADING_APP]: (state, loading) => {
    state.loading = loading
  },
  [types.SET_APP_CODE]: (state, code) => {
    state.code = code
  },
  [types.SET_BTN_VISIBLE]: (state, { isShowBurger, isShowBasket }) => {
    if (isShowBasket === true || isShowBasket === false) {
      state.showBtnBasket = isShowBasket
    }
    if (isShowBurger === true || isShowBurger === false) {
      state.showBtnBurger = isShowBurger
    }
  },
  [types.SET_SITECONFIG]: (state, payload) => {
    state.config = { ...state.config, ...payload }
  }
}
