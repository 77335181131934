import panelBurgerTypes from '@/store/panelBurger/types'
import { mapActions } from 'vuex'
import DocDialog from '@/components/Modal/Owner/DocDialog'

export default {
  methods: {
    ...mapActions('panelBurger', [panelBurgerTypes.GET_SITE_DOC_ACTION]),
    async docsDialog(docType) {
      try {
        const docs = await this[panelBurgerTypes.GET_SITE_DOC_ACTION]({
          Oferta: docType === 1,
          Privacy: docType === 2
        })
        const { OfertaText, PrivacyText } = docs
        if (docs) {
          const isMobile = this.$helper?.isMobile()

          this.$FModal.show(
            {
              component: DocDialog,
              width: isMobile ? '100%' : 769,
              contentStyles: {
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
              },
            },
            {
              text: docType === 1 ? OfertaText : PrivacyText,
              title: docType === 1 ? 'Оферта' : 'Политика конфиденциальности',
            }
          )
        }
      } catch (e) {
        console.log('error', e)
      }
    }
  }
}
