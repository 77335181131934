import types from './types'

export default {
  [types.SET_SITE_BURGER]: (state, payload) => {
    delete payload?.ErrorCode
    delete payload?.Message
    state.pageBurger = payload
  },
  [types.SET_SITE_RULE]: (state, payload) => {
    state.pageRules = payload
  },
  [types.SET_SITE_WORK]: (state, payload) => {
    state.pageWorks = payload
  },
  [types.CURRENT_BURGER_PAGE_SET]: (state, page) => {
    state.page = page
  },
  [types.TOGGLE_PANEL_BURGER]: state => {
    state.show = !state.show
  }
}
