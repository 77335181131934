import types from './types'

export default {
  [types.SET_PAGE_OWNER_CONFIG]: (state, config) => {
    delete config.Message
    delete config.ErrorCode
    state.pageOwnerConfig = config
  },
  // [types.SET_OWNER_FRIEND]: (state, data) => {
  //   state.ownerFriendId = data
  // },
  [types.SET_BALANCE_IDENTITY]: (state, data) => {
    state.balanceIdentity = data
  },
  [types.SET_CERT_OWNER]: (state, data) => {
    delete data.Message
    delete data.ErrorCode
    state.owner = data
  }
}
