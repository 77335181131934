<template>
  <div class="pb mt-15" :class="{ 'doc-scroll': docOpen }">
    <MlLoading v-if="loading" />
    <div v-else>
      <div
        class="mloyalty-faq-item"
        ref="mloyalty-faq-item-oferta"
        v-if="docOferta"
      >
        <div class="mloyalty-question" @click="toggleFaq('oferta')">
          Оферта
          <img
            class="mloyalty-faq-arrow-right"
            src="~@/assets/img/faq-arrow-right.png"
          />
          <img
            class="mloyalty-faq-arrow-down"
            src="~@/assets/img/faq-arrow-down.png"
          />
        </div>
        <div class="mloyalty-answer" ref="answer-oferta">{{ docOferta }}</div>
      </div>
      <div
        class="mloyalty-faq-item"
        ref="mloyalty-faq-item-privacy"
        v-if="docPolicy"
      >
        <div class="mloyalty-question" @click="toggleFaq('privacy')">
          Политика конфидециальности
          <img
            class="mloyalty-faq-arrow-right"
            src="~@/assets/img/faq-arrow-right.png"
          />
          <img
            class="mloyalty-faq-arrow-down"
            src="~@/assets/img/faq-arrow-down.png"
          />
        </div>
        <div class="mloyalty-answer" ref="answer-privacy">{{ docPolicy }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import panelBurgerTypes from '@/store/panelBurger/types'

export default {
  name: 'Offer-Page',
  data: () => ({
    loading: false,
    docOferta: null,
    docPolicy: null,
    docOpen: false
  }),
  mounted() {
    this.setInitialize()
  },
  methods: {
    ...mapActions('panelBurger', [panelBurgerTypes.GET_SITE_DOC_ACTION]),
    async setInitialize() {
      this.loading = true
      try {
        const docs = await this[panelBurgerTypes.GET_SITE_DOC_ACTION]({
          Oferta: true,
          Privacy: true
        })
        if (docs) {
          this.docOferta = docs.OfertaText
          this.docPolicy = docs.PrivacyText
        }
        this.loading = false
      } catch (e) {
        console.log('error', e)
      }
    },
    toggleFaq(id) {
      let elFaq = this.$refs['mloyalty-faq-item-' + id]
      elFaq.classList.toggle('active')
      let el = this.$refs['answer-' + id]
      if (el.style.maxHeight) {
        el.style.maxHeight = null
        setTimeout(() => {
          el.style.paddingTop = '0px'
          this.docOpen = false
        }, 150)
      } else {
        el.style.paddingTop = '10px'
        el.style.maxHeight = el.scrollHeight + 10 + 'px'
        this.docOpen = true
      }
    }
  }
}
</script>
