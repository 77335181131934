import ApiBase from './_ApiBase'

export default {
  /**
   *
   * @param {Object} data (required fields SiteCode, Operator, Token)
   */
  getSiteConfig: data => {
    return ApiBase.post('api/sertcard/SiteConfig', data)
  },
  fetchConfirmPageConfig(data) {
    return ApiBase.post('api/sertcard/SitePageOrderConfirm', data)
  },
  /**
   * Object -
   * {
   *  "SiteCode": "sample string 1",
   *  "Theme": "sample string 2",
   *  "Operator": 1,
   *  "Token": 1
   * }
   * @param {Object} data
   */
  fetchOrderPageConfig(data) {
    return ApiBase.post('api/sertcard/SitePageOrder', data)
  },
  /**
   * Object -
   * {
   *  "SiteCode": "sample string 1",
   *  "Theme": "sample string 2",
   *  "Operator": 1,
   *  "Token": 1
   * }
   * @param {Object} data
   */
  fetchContactPageConfig(data) {
    return ApiBase.post('api/sertcard/SitePageContacts', data)
  },
  /**
   * Object -
   * {
   *  "SiteCode": "sample string 1",
   *  "Theme": "sample string 2",
   *  "Operator": 1,
   *  "Token": 1
   *  "PageType": "sample string 3"
   * }
   * @param {Object} data
   */
  fetchBasketPageConfig(data) {
    return ApiBase.post('api/sertcard/SitePageBasket', data)
  }
}
