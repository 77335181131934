/**
 * Конфигурационный файл
 * jwtu и jwtp - имя пользователя и пароль для авторизации в API.
 * Используются только в методе managerlogin, для получения JWT.
 * Остальные методы для авторизации используют уже JWT.
 * useLS - использовать для хранения токенов локальное хранилище браузера, по умолчанию false.
 * Т.к. виджет запускается в iframe, настройками безопасности браузера ему может быть
 * запрещён доступ к localStorage, поэтому данный способ хранения не рекомендуется.
 * При отключении localStorage, будет использоваться store приложения.
 */
export default {
  jwtu: process.env.VUE_APP_USERNAME,
  jwtp: process.env.VUE_APP_PASSWORD,
  useLS: false,
  defaultModal: {
    height: 'auto',
    maxWidth: 400,
    adaptive: true,
    transition: 'nice-modal-fade',
    clickToClose: true
  }
}
