export default {
  SET_LOADING_APP: 'SET_LOADING_APP',
  INIT_TEMPLATE: 'INIT_TEMPLATE',
  TEMPLATE_CODE_SET: 'TEMPLATE_CODE_SET',
  SET_TYPE_BROWSER: 'SET_TYPE_BROWSER',
  SET_TYPE_DESKTOP: 'SET_TYPE_DESKTOP',
  SET_APP_CODE: 'SET_APP_CODE',
  SET_BTN_VISIBLE: 'SET_BTN_VISIBLE',
  SET_BOTTOM_OFFSET: 'SET_BOTTOM_OFFSET',
  SET_OPACITY: 'SET_OPACITY',
  SET_SITECONFIG: 'SET_SITECONFIG',
  SET_ORDER_PAGE_CONFIG: 'SET_ORDER_PAGE_CONFIG',
  SET_CONTACT_PAGE_CONFIG: 'SET_CONTACT_PAGE_CONFIG',
  SET_BASKET_PAGE_CONFIG: 'SET_BASKET_PAGE_CONFIG',
  SET_CONFIRM_PAGE_CONFIG: 'SET_CONFIRM_PAGE_CONFIG',
  SET_APP_CODE_ACTION_FOR_PAYMENTS: 'SET_APP_CODE_ACTION_FOR_PAYMENTS',
  SET_APP_CODE_ACTION_WITHOUT_CONFIGS: 'SET_APP_CODE_ACTION_WITHOUT_CONFIGS',
  SET_APP_CODE_ACTION: 'SET_APP_CODE_ACTION',
  GET_SITE_CONFIGS: 'GET_SITE_CONFIGS',
  GET_SITE_CONFIGS_FOR_PAYMENT_ACTION: 'GET_SITE_CONFIGS_FOR_PAYMENT_ACTION',
  GET_ORDER_PAGE_CONFIG: 'GET_ORDER_PAGE_CONFIG',
  GET_CONTACT_PAGE_CONFIG: 'GET_CONTACT_PAGE_CONFIG',
  GET_BASKET_PAGE_CONFIG: 'GET_BASKET_PAGE_CONFIG',
  GET_CONFIRM_PAGE_CONFIG: 'GET_CONFIRM_PAGE_CONFIG',
  REDIRECT_TO_MAIN_ACTION: 'REDIRECT_TO_MAIN_ACTION',
  SET_APP_HOSTNAME: 'SET_APP_HOSTNAME',
  SET_APP_PROTOCOL: 'SET_APP_PROTOCOL',
  SET_APP_PATHNAME: 'SET_APP_PATHNAME',
  SET_STATUS_PAYMENT_PAGE_CONFIG: 'SET_STATUS_PAYMENT_PAGE_CONFIG',
  SET_BACK_URL: 'SET_BACK_URL'
}
