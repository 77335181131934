import store from '@/store'
import appTypes from '@/store/app/types'

export default async (to, from, next) => {
  // Если конфиг для страницы Basket отстуствует, получаем его прежде чем перейти на страницу
  if (!store?.state?.app?.pageBasketConfig) {
    await store.dispatch(`app/${appTypes.GET_BASKET_PAGE_CONFIG}`)
  }
  return next()
}
