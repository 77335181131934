import types from './types'
import basketTypes from '@/store/basket/types'
import panelBurgerTypes from '@/store/panelBurger/types'
import yookassaTypes from '@/store/yookassa/types'
import router from '@/router'
import { NOT_AUTHENTICATED } from '@/helpers/const/errors'
import ConfigService from '@/api/ConfigService'
import widgetRoutes from '@/helpers/const/widgetRoutes'
import { v4 as uuid } from 'uuid'
import { cloneDeep } from 'lodash'
import ErrorService from '@/helpers/services/errorService'

const ROUTE_NAME_ACCESS_DENIED = 'AccessDenied'
const EMPTY_FRIEND = {
  channel: null,
  date: null,
  email: null,
  name: null,
  phone: null,
  time: null
}

export default {
  /**
   * Получение настроек страницы Подтверждения заказа
   */
  [types.GET_CONFIRM_PAGE_CONFIG]: async (
    { state, rootState, commit, getters },
    { pageType }
  ) => {
    const theme = state?.config?.Theme
    const siteCode = getters?.getSiteCode
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    if (!operator || !theme || !siteCode || !token) {
      router.push({
        name: 'Error',
        params: {
          message: 'GET_CONTACT_PAGE_CONFIG: не определен один из параметров'
        }
      })
    }
    const data = {
      Theme: theme,
      SiteCode: siteCode,
      Operator: operator,
      Token: token,
      PageType: pageType
    }
    return ConfigService.fetchConfirmPageConfig(data)
      .then(response => {
        if (response?.status === 200) {
          commit(types.SET_CONFIRM_PAGE_CONFIG, response?.data)
        } else {
          return ErrorService.throw(
            `Получение настроек страницы "Подтверждение" завершилось ошибкой`
          )
        }
      })
      .catch(error => {
        return ErrorService.throw(`GET_CONFIRM_PAGE_CONFIG: ${error}`)
      })
  },
  /**
   * Получение настроек страницы оформления заказа
   */
  [types.GET_BASKET_PAGE_CONFIG]: async ({
    state,
    rootState,
    commit,
    getters
  }) => {
    const theme = state?.config?.Theme
    const siteCode = getters?.getSiteCode
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    if (!operator || !theme || !siteCode || !token) {
      router.push({
        name: 'Error',
        params: {
          message: 'GET_CONTACT_PAGE_CONFIG: не определен один из параметров'
        }
      })
    }
    const data = {
      Theme: theme,
      SiteCode: siteCode,
      Operator: operator,
      Token: token
    }
    return ConfigService.fetchBasketPageConfig(data)
      .then(response => {
        if (response?.status === 200) {
          const { Goods } = response?.data
          commit(types.SET_BASKET_PAGE_CONFIG, response?.data)
          if (Goods?.length) {
            const basket = []
            Goods.forEach(item => {
              basket.push({
                certificate: {
                  id: undefined,
                  img: item?.Design
                },
                id: uuid(),
                congratulation: item.Congratulation,
                price: item.Price,
                count: item.Quantity,
                friend: cloneDeep(EMPTY_FRIEND)
              })
            })
            commit(`basket/${basketTypes.SET_BASKET}`, basket, { root: true })
          }
        } else {
          throw new Error(
            'Получение настроек страницы "Корзина" завершилось ошибкой'
          )
        }
      })
      .catch(error => {
        router.push({
          name: 'Error',
          params: {
            message: `GET_BASKET_PAGE_CONFIG: ${error}`
          }
        })
      })
  },

  /**
   * Перенаправляет на главную страницу из конфига
   */
  [types.REDIRECT_TO_MAIN_ACTION]: async ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      const mainPage = getters.getMainPage
      if (!mainPage?.Name) {
        router.push({ name: 'Error404' })
        return resolve()
      }
      const nameRoute = mainPage.Name
      const findRoute = widgetRoutes.find(
        route => route?.configName?.toLowerCase() === nameRoute?.toLowerCase()
      )
      if (findRoute && findRoute.path) {
        const currentPath = router?.currentRoute?.path
        if (currentPath !== findRoute?.path) {
          if (
            router?.currentRoute?.Name?.toLowerCase() !==
            findRoute?.routeName?.toLowerCase()
          ) {
            console.log('REDIRECT TO', findRoute?.path)
            router.push({ name: findRoute.routeName })
          }
        }
      }
      // commit('app/SET_LOADING_APP', false, { root: true })
      // console.log('loading end REDIRECT_TO_MAIN_ACTION')
      return resolve()
    })
  },
  /**
   * Получение настроек страницы оформления заказа
   */
  [types.GET_CONTACT_PAGE_CONFIG]: async ({
    state,
    rootState,
    commit,
    getters
  }) => {
    const theme = state?.config?.Theme
    const siteCode = getters?.getSiteCode
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    if (!operator || !theme || !siteCode || !token) {
      router.push({
        name: 'Error',
        params: {
          message: 'GET_CONTACT_PAGE_CONFIG: не определен один из параметров'
        }
      })
    }
    const data = {
      Theme: theme,
      SiteCode: siteCode,
      Operator: operator,
      Token: token
    }
    return ConfigService.fetchContactPageConfig(data)
      .then(response => {
        if (response?.status === 200) {
          commit(types.SET_CONTACT_PAGE_CONFIG, response?.data)
          // console.log('SET_CONTACT_PAGE_CONFIG FINISH', response?.data)
        } else {
          throw new Error(
            'Получение настроек страницы "Контакты" завершилось ошибкой'
          )
        }
      })
      .catch(error => {
        router.push({
          name: 'Error',
          params: {
            message: `GET_CONTACT_PAGE_CONFIG: ${error}`
          }
        })
      })
  },
  /**
   * Получение настроек страницы оформления заказа
   */
  [types.GET_ORDER_PAGE_CONFIG]: async ({
    state,
    rootState,
    commit,
    getters
  }) => {
    // console.log('GET_ORDER_PAGE_CONFIG START')
    const theme = state?.config?.Theme
    const siteCode = getters?.getSiteCode
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    const pageType = getters?.getOrderPageType
    if (!operator || !theme || !siteCode || !token) {
      router.push({
        name: 'Error',
        params: {
          message: 'GET_ORDER_PAGE_CONFIG: не определен один из параметров'
        }
      })
    }
    const data = {
      Theme: theme,
      SiteCode: siteCode,
      Operator: operator,
      Token: token,
      PageType: pageType
    }
    console.log(data)
    return ConfigService.fetchOrderPageConfig(data)
      .then(response => {
        // console.log('GET_ORDER_PAGE_CONFIG RESPONSE', response, data)
        if (response?.status === 200) {
          commit(types.SET_ORDER_PAGE_CONFIG, response?.data)
          // console.log('GET_ORDER_PAGE_CONFIG FINISH')
        } else {
          throw new Error(
            'Получение настроек страницы "Заказ" завершилось ошибкой'
          )
        }
      })
      .catch(error => {
        router.push({
          name: 'Error',
          params: {
            message: `GET_ORDER_PAGE_CONFIG: ${error}`
          }
        })
      })
  },
  [types.SET_APP_CODE_ACTION_FOR_PAYMENTS]: async (
    { commit, dispatch, state, rootState },
    { sessionId, paymentId, code }
  ) => {
    commit('app/SET_LOADING_APP', true, { root: true })
    commit(types.SET_APP_CODE, code)
    // await dispatch(
    //   `yookassa/${yookassaTypes.GET_PAYMENT_STATUS_ACTION}`,
    //   {
    //     paymentId
    //   },
    //   { root: true }
    // )
    await dispatch(types.GET_SITE_CONFIGS_FOR_PAYMENT_ACTION, {
      paymentId,
      sessionId
    })

    if (!rootState.panelBurger?.pageBurger) {
      console.log('CALL PANEL')
      await dispatch(
        `panelBurger/${panelBurgerTypes.GET_SITE_BURGER_ACTION}`,
        {},
        {
          root: true
        }
      )
    }

    await dispatch(
      `yookassa/${yookassaTypes.SITE_PAGE_STATUS_PAYMENT}`,
      {},
      { root: true }
    )

    await dispatch(types.REDIRECT_TO_MAIN_ACTION)
    commit('app/SET_LOADING_APP', false, { root: true })
  },
  [types.SET_APP_CODE_ACTION_WITHOUT_CONFIGS]: async (
    { commit, dispatch, getters },
    payload
  ) => {
    console.log('loading start SET_APP_CODE_ACTION_WITHOUT_CONFIGS')
    commit('app/SET_LOADING_APP', true, { root: true })
    commit(types.SET_APP_HOSTNAME, 'any')
    const code = payload
    commit(types.SET_APP_CODE, code)
    // запрашиваем общие настройки сайта
    await dispatch(types.GET_SITE_CONFIGS)
    // setTimeout(() => {
    commit('app/SET_LOADING_APP', false, { root: true })
    // }, 1500)
  },
  /**
   * Устанавливает app code, запускает получение конфига
   */
  [types.SET_APP_CODE_ACTION]: async (
    { state, commit, dispatch, getters, rootState },
    payload
  ) => {
    // console.log('loading start SET_APP_CODE_ACTION')
    commit('app/SET_LOADING_APP', true, { root: true })
    // const debounceTimeout = process.env.VUE_APP_DEBOUNCE_TIMEOUT ?? 1000
    const { code } = payload
    const { toMain } = payload
    const { isDesktop } = payload
    // const { reloadConfigs } = payload
    console.log('SET_APP_CODE_ACTION', code, toMain)
    // console.log('SET_APP_CODE_ACTION PAYLOAD', payload)
    commit(types.SET_APP_CODE, code)
    commit(types.SET_TYPE_DESKTOP, isDesktop)
    // await debounce(async () => {
    // запрашиваем общие настройки сайта
    // console.log('AWAIT  SET_APP_CODE_ACTION/ GET_SITE_CONFIGS')
    await dispatch(types.GET_SITE_CONFIGS)
    //Если конфиг для страницы Order не задан вызываем получение конфига
    // if (!state?.pageOrderConfig) {
    // console.log('AWAIT  SET_APP_CODE_ACTION/ GET_ORDER_PAGE_CONFIG')
    !payload?.notGetOrderPageConfig && await dispatch(types.GET_ORDER_PAGE_CONFIG)
    // }
    console.log('11111')
    if (!rootState.panelBurger?.pageBurger && !payload?.notGetSiteBurger) {
      console.log('2222')
      await dispatch(
        `panelBurger/${panelBurgerTypes.GET_SITE_BURGER_ACTION}`,
        {},
        {
          root: true
        }
      )
    }
    if (toMain === true) {
      // || (toMain === false && currentPage?.path !== findPath?.path)
      // console.log('AWAIT  SET_APP_CODE_ACTION/ REDIRECT_TO_MAIN_ACTION')
      await dispatch(types.REDIRECT_TO_MAIN_ACTION)
    }
    // return Promise.resolve()
    // }, debounceTimeout)()
    setTimeout(() => {
      commit('app/SET_LOADING_APP', false, { root: true })
    }, 850)
    // console.log('loading end SET_APP_CODE_ACTION')
  },
  [types.GET_SITE_CONFIGS_FOR_PAYMENT_ACTION]: async (
    { state, rootState, commit, getters },
    { paymentId, sessionId }
  ) => {
    const operator = rootState?.auth?.decodeJwt?.oper
    const decodeToken = rootState?.auth?.decodeJwt?.token
    const siteCode = getters.getSiteCode
    const hostname = state?.hostname
    const data = {
      Operator: operator,
      Token: decodeToken,
      SiteCode: siteCode,
      SourceUrl: hostname,
      SessionId: sessionId,
      PaymentId: paymentId
    }
    if (
      !operator ||
      !decodeToken ||
      !siteCode ||
      !hostname ||
      !paymentId ||
      !sessionId
    ) {
      console.error(
        'GET_SITE_CONFIGS_FOR_PAYMENT_ACTION',
        'Не задан один из параметров',
        data
      )
    }
    return ConfigService.getSiteConfig(data).then(response => {
      if (response?.status === 200) {
        const { data } = response
        delete data.ErrorCode
        delete data.Message
        commit(types.SET_SITECONFIG, data)
        // console.log('SITE_CONFIGS FINISH', data)
      }
    })
  },
  [types.GET_SITE_CONFIGS]: async ({ state, rootState, commit, getters }) => {
    const operator = rootState?.auth?.decodeJwt?.oper
    const decodeToken = rootState?.auth?.decodeJwt?.token
    const siteCode = getters.getSiteCode
    const hostname = state?.hostname
    const sessionId = getters.getSessionId
    const data = {
      Operator: operator,
      Token: decodeToken,
      SiteCode: siteCode,
      SourceUrl: hostname
    }
    if (sessionId) {
      data.SessionId = sessionId
    }
    if (!operator || !decodeToken || !siteCode || !hostname) {
      if (
        router?.currentRoute?.name?.toLowerCase() !==
        ROUTE_NAME_ACCESS_DENIED.toLocaleLowerCase()
      ) {
        console.log('GET_SITE_CONFIGS REDIREC 111', data)
        router.push({
          name: 'AccessDenied',
          params: { type: NOT_AUTHENTICATED }
        })
      }
    }

    return ConfigService.getSiteConfig(data).then(response => {
      if (response?.status === 200) {
        const { data } = response
        delete data.ErrorCode
        delete data.Message
        commit(types.SET_SITECONFIG, data)
        // console.log('SITE_CONFIGS FINISH', data)
      }
    })
  }
}
