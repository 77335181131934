import store from '@/store'
import appTypes from '@/store/app/types'

export default async (to, from, next) => {
  // Если конфиг для страницы Order отстуствует, получаем его прежде чем перейти на страницу
  // console.log('ORDER GUARD', store?.state?.app?.pageOrderConfig)
  // if (!store?.state?.app?.pageOrderConfig) {
  // console.log('ORDER GUARD AWAIT START')
  await store.dispatch(`app/${appTypes.GET_ORDER_PAGE_CONFIG}`)
  // console.log('ORDER GUARD AWAIT END')
  // }
  return next()
}
