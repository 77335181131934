export default {
  REQUEST_CODE: 'REQUEST_CODE',
  SEND_VERIFICATIONCODE_VIA_SMS: 'SEND_VERIFICATIONCODE_VIA_SMS',
  // SEND_VERIFICATIONCODE_VIA_MESSANGER: 'SEND_VERIFICATIONCODE_VIA_MESSANGER',
  INIT_VERIFICATION_CODE: 'INIT_VERIFICATION_CODE',
  INIT_VERIFICATION_CODE_PROCESS: 'INIT_VERIFICATION_CODE_PROCESS',
  SET_CONTACTS: 'SET_CONTACTS',
  SET_TEST_CODE: 'SET_TEST_CODE',
  SET_EDIT_CONTACT_MODE: 'SET_EDIT_CONTACT_MODE'
}
