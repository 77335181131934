export default {
  order: null,
  pageSubscriptionConfig: null, // Настройки страницы "Заказ-Подписка"
  friend: {
    name: null,
    phone: null,
    email: null,
    date: null,
    time: null,
    channel: null
  }
}
