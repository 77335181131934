import types from './types'
import FaqService from '@/api/FaqService'
import router from '@/router'

export default {
  [types.GET_FAQS]: async ({ commit, rootGetters, rootState }, payload) => {
    const siteCode = rootGetters['app/getSiteCode']
    const operator = rootState?.auth?.decodeJwt?.oper
    const token = rootState?.auth?.decodeJwt?.token
    const isWidgetBurger = payload?.isWidgetBurger
    const isWidgetPreview = payload?.isWidgetPreview
    const isSertOwnerSite = payload?.isSertOwnerSite
    if (!operator || !siteCode || !token) {
      router.push({
        name: 'Error',
        params: {
          message: 'GET_FAQS: не определен один из параметров'
        }
      })
    }
    const data = {
      SiteCode: siteCode,
      Operator: operator,
      Token: token,
      VidgetBurger: isWidgetBurger,
      VidgetPreview: isWidgetPreview,
      SertOwnerSite: isSertOwnerSite
    }
    const response = await FaqService.getSiteFaq(data)
    if (
      response?.status === 200 &&
      !response?.data?.Message &&
      !response?.data?.ErrorCode
    ) {
      commit(types.SET_FAQS, response?.data?.QA)
      return Promise.resolve()
    }
    return Promise.reject('action GET_FAQS error', response)
  }
}
