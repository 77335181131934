import ApiBase from './_ApiBase'

export default {
  // createOwner(data) {
  //   return ApiBase.post('api/sertcard/SertcardOwnerCreate', data)
  // },
  verifyCardAndPinCode(data) {
    return ApiBase.post('api/sertcard/SecretIdentify', data)
  },
  createOrUpdateOwnerOrFriend(data) {
    return ApiBase.post('api/sertcard/Owner', data)
  },
  getSitePageSertOwner(data) {
    return ApiBase.post('api/sertcard/SitePageSertOwner', data)
  },
  sendForEmail(data) {
    return ApiBase.post('api/sertcard/SendForEmail', data)
  },
  getSertCardInfo(data) {
    return ApiBase.post('api/sertcard/SertCardInfo', data)
  }
}
