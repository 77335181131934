import ApiBase from './_ApiBase'

export default {
  getSiteWork(data) {
    return ApiBase.post('api/sertcard/SiteWork', data)
  },
  getSiteRule(data) {
    return ApiBase.post('api/sertcard/SiteRule', data)
  },
  getSiteDoc(data) {
    return ApiBase.post('api/sertcard/SiteDoc', data)
  },
  getSiteBurger(data) {
    return ApiBase.post('api/sertcard/SitePageBurger', data)
  }
}
