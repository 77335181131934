import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vuetify/dist/vuetify.min.css'
import './assets/scss/_app.scss'
import Vuetify from 'vuetify'
import VueYandexMetrika from 'vue-yandex-metrika'
import Auth from './plugins/auth'
import VueConfetti from 'vue-confetti'
import './plugins/components'
import './plugins/linkify'
import './plugins/helper'
import './plugins/constant'
import { mapState } from 'vuex'
import VueJsModal from 'vue-js-modal'
import FullpageModal from 'vue-fullpage-modal'

Vue.use(Auth)
Vue.use(Vuetify)
Vue.use(VueYandexMetrika, {
  id: '70564408',
  router: router,
  env: process.env.NODE_ENV
})
Vue.use(VueConfetti)
Vue.use(VueJsModal, { dynamic: true })
Vue.use(FullpageModal)

Vue.config.productionTip = false
new Vue({
  vuetify: new Vuetify(),
  router,
  store,
  render: h => h(App),
  computed: {
    ...mapState({
      code: state => state?.app?.code
    })
  },
  async created() {
    this.$router.push(`${window.location.pathname}${window.location.search}`)

    const code = window?.xprops?.code
    if (code && code !== this.code && code !== '*') {
      await store.dispatch('app/SET_APP_CODE_ACTION', {
        code: code,
        toMain: true,
        isDesktop: false
      })
    }
  }
}).$mount('#app')
