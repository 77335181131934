import Constants from '@/helpers/constants'
import widgetRoutes from '@/helpers/const/widgetRoutes'

export default {
  /**
   * Получает PageType по названию страницы конфига
   */
  getPageType: state => routeName => {
    if (!routeName) return null
    const findRoute = widgetRoutes?.find(
      item => item?.routeName?.toLowerCase() === routeName?.toLowerCase()
    )
    if (!findRoute) return null
    const findPage = state?.config?.PageConfig?.find(
      page => page?.Name?.toLowerCase() === findRoute?.configName?.toLowerCase()
    )
    if (!findPage) return null
    return findPage?.Type || null
  },
  /**
   * Получает PageType по названию страницы конфига
   */
  getOrderPageType: state => {
    const pageName = Constants?.PAGE_CONFIG?.ORDER
    const find = state?.config?.PageConfig?.find(
      item => item?.Name === pageName
    )
    return find?.Type
  },
  /**
   * Признак десктоп или виджет
   * @param state
   * @returns {boolean}
   */
  getIsDesktop: state => {
    return state?.isDesktop || false
  },
  /**
   * Получение главной страницы из конфига
   * @param {Object} state
   */
  getMainPage: state => {
    return state?.config?.PageConfig?.find(config => config?.MainPage === true)
  },
  getSessionId: state => {
    return state?.config?.SessionId ?? null
  },
  getSiteCode: (state, getters, rootState) => {
    const typeWidget = state?.code
    const operator = rootState?.auth?.decodeJwt?.oper
    const partner = rootState?.auth?.decodeJwt?.partner
    const poscode = rootState?.auth?.decodeJwt?.poscode
    const token = rootState?.auth?.decodeJwt?.token
    let siteCode = '*'
    if (
      typeWidget
      // typeWidget >= 1 &&
      // typeWidget <= 3 &&
      // operator &&
      // partner &&
      // poscode &&
      // token
    ) {
      // siteCode = `${typeWidget}${operator}${partner}${poscode}${token}`
      siteCode = typeWidget
    }
    return siteCode
  },
  /**
   * Проверяет имеется ли в конфиге полученного с сервера, доступ к странице бургера "Как это работает"
   * @param {Object} state
   */
  hasBurgerWorkRule: state => {
    const findRule = state?.config?.PageConfig?.find(
      config => config?.Name === Constants?.CONFIG_BURGER?.WORK_PAGE
    )
    return !!findRule
  },
  /**
   * Проверяет имеется ли в конфиге полученного с сервера, доступ к странице бургера "Частые вопросы"
   * @param {Object} state
   */
  hasBurgerFaqRule: state => {
    const findRule = state?.config?.PageConfig?.find(
      config => config?.Name === Constants?.CONFIG_BURGER?.FAQ_PAGE
    )
    return !!findRule
  },
  /**
   * Проверяет имеется ли в конфиге полученного с сервера, доступ к странице бургера "Офферта"
   * @param {Object} state
   */
  hasBurgerOfferRule: state => {
    const findRule = state?.config?.PageConfig?.find(
      config => config?.Name === Constants?.CONFIG_BURGER?.OFFER_PAGE
    )
    return !!findRule
  },
  /**
   * Проверяет имеется ли в конфиге полученного с сервера, доступ к странице бургера "Поддержка"
   * @param {Object} state
   */
  hasBurgerHelpRule: state => {
    const findRule = state?.config?.PageConfig?.find(
      config => config?.Name === Constants?.CONFIG_BURGER?.HELP_PAGE
    )
    return !!findRule
  },
  /**
   * Проверяет имеется ли в конфиге полученного с сервера, доступ к странице бургера "Правила"
   * @param {Object} state
   */
  hasBurgerRulesRule: state => {
    const findRule = state?.config?.PageConfig?.find(
      config => config?.Name === Constants?.CONFIG_BURGER?.RULE_PAGE
    )
    return !!findRule
  },
  /**
   * Проверяет имеется ли в конфиге полученного с сервера, доступ к странице "Предпросмотр"
   * @param {Object} state
   */
  hasPreviewRule: state => {
    const findRule = state?.config?.PageConfig?.find(
      config => config?.Name === Constants?.CONFIG_PREVIEW_PAGE
    )
    return !!findRule
  },
  hasOwnerAnimationPage: state => {
    const findRule = state?.config?.PageConfig?.find(
      config => config?.Name === Constants?.CONFIG_OWNER?.ANIMATION_PAGE
    )
    return !!findRule
  },
  hasOwnerPage: state => {
    const findRule = state?.config?.PageConfig?.find(
      config => config?.Name === Constants?.CONFIG_OWNER?.OWNER_PAGE
    )
    return !!findRule
  },
  hasOrderPage: state => {
    const findRule = state?.config?.PageConfig?.find(
      config => config?.Name === Constants?.CONFIG_OWNER?.ORDER_PAGE
    )
    return !!findRule
  }
}
