<template>
  <div class="pb pt-1">
    <div class="row how-use " style="border: none">
      <div class="col-12">
        <div class="item" v-for="(rule, index) in rules" :key="index">
          <div class="numb">{{ rule.Step }}</div>
          <div class="content" v-linkified:options="{ className: 'ml-link' }">
            {{ rule.TextRule }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import panelBurgerTypes from '@/store/panelBurger/types'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Rules-Page',
  data: () => ({
    loading: false
  }),
  mounted() {
    this.setInitialize()
  },
  computed: {
    ...mapState({
      rules: state => state?.panelBurger?.pageRules
    })
  },
  methods: {
    ...mapActions('panelBurger', [panelBurgerTypes.GET_SITE_RULE_ACTION]),
    async setInitialize() {
      this.loading = true
      try {
        await this[panelBurgerTypes.GET_SITE_RULE_ACTION]()
        this.loading = false
      } catch (e) {
        console.log('error', e)
      }
    }
  }
}
</script>
