import store from '@/store'
import appTypes from '@/store/app/types'

export default async (to, from, next) => {
  // console.log('CONFIG GUARD')
  const noGuard = to?.meta?.noGuard
  const noConfig = to?.meta?.noConfig
  if (noGuard || noConfig) return next()

  // Определяем сколько настроек в объекте config, если 1 поле, то считает конфиг не инициализированным и запрашиваем его с сервера
  const configFieldsCount = Object.keys(store.state.app.config).length
  if (configFieldsCount <= 1) {
    try {
      await store.dispatch(`app/${appTypes.GET_SITE_CONFIGS}`)
      // await getSiteConfig()
    } catch (error) {
      return next({ name: 'Error', params: { message: error } })
    }
  }
  return next()
}
