import store from '@/store'

export default async (to, from, next) => {
  const isPageType = await store?.getters?.['app/getMainPage']
  console.log('isPageType', isPageType)
  if (!isPageType) {
    next({ name: 'Error404' })
  }
  return next()
}
