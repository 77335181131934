<template>
  <v-app>
    <v-main :class="currentTheme">
      <modals-container />

      <!-- <div class="h100 ml-preview-page mloyalty-preview"> -->
      <div class="h100 ml-preview-page">
        <div v-if="show && isPreview" class="mloyalty-panel-header-2 px-8 ">
          <a
            href="#"
            class="d-flex align-items-center ml-title-14-20 ml-text-grey"
            style="position: absolute;"
            @click.prevent="goBack"
          >
            <img src="@/assets/img/arrow-left.png" alt="" class="mr-2" />
            Назад
          </a>
          <div class="ml-text-16-24-600 flex-grow-1 text-center">
            Предпросмотр
          </div>
        </div>
        <div
          v-if="!show && !testingMode"
          class="h100 d-flex justify-content-center align-items-center"
        >
          <SwipeButton
            ref="swipeButton"
            initialInstructionText="потяните, чтобы открыть"
            class="ml-swipe-button"
            @actionConfirmed="onActionConfirmed"
          />
        </div>
        <div
          class="ml-preview-container ml-preview-container--wide  px-4 py-5"
          v-show="show || testingMode"
        >
          <img
            class="ml-confeti"
            ref="confeti"
            src="@/assets/img/confetti.png"
            alt=""
            v-if="hasOwnerAnimationPage && !testingMode"
          />
          <!-- <div class="ml-perview-card ml-perview-card-shrink" ref="preview"> -->
          <div
            class="ml-perview-card   pt-5 pb-10"
            :class="{ 'ml-perview-card-shrink': !testingMode }"
            ref="preview"
          >
            <div class="ml-pervie-card-wrapper">
              <div class="row">
                <div
                  class="col-12 text-center d-flex flex-column align-items-center"
                >
                  <img
                    v-if="sellerLogo"
                    :src="sellerLogo"
                    alt=""
                    class="mb-6 ml-owner-top-image"
                  />
                  <div class="ml-preview-page_title1 mt-4 ">
                    {{ sertName }}
                    <!-- Сертификат на 3000 ₽ -->
                  </div>
                  <!-- <div
                    class="ml-preview-page_title1 ml-preview-page_title1__mb7px"
                  >
                    «РивГош»
                  </div> -->
                  <div class="ml-preview-page_expiration mb-3">
                    Действует до {{ sertDate }}
                  </div>
                  <div
                    class="ml-preview-page_congratulation ml-preview-page_congratulation__mt26"
                  >
                    {{ sertCongratulation }}
                    <!-- С днем рождения! Желаю крепкого здоровья, удачи,
                    благополучия, добра, радости, любви, счастья, хорошего
                    настроения, улыбок, ярких впечатлений. Пусть тепло и уют
                    всегда наполняют твой дом, пусть солнечный свет согревает в
                    любую погоду, при одной мысли о них. -->
                  </div>
                </div>
                <div class="col-12 d-flex flex-column align-items-center pt-7">
                  <v-img
                    :aspect-ratio="16 / 11"
                    class="ml-img-rounded mb-10 mt-2 ml-preview-page_certificate-image"
                    :src="sertImage"
                  >
                    <div class="ml-basket-price-label2">{{ price }}₽</div>
                  </v-img>
                  <!-- <img
                    src="@/assets/img/example/riv-gosh-bar-code.png"
                    class="ml-preview-page_bar-code__mt32 ml-preview-page_bar-code mb-5"
                    alt=""
                  /> -->
                  <barcode
                    :value="sertNumber"
                    ref="barcode"
                    :lineColor="'black'"
                    :text="' '"
                    :width="4"
                  >
                    Show this if the rendering fails.
                  </barcode>
                  <div class="ml-preview-page_card-number mb-3">
                    Номер карты: {{ sertNumber }}
                  </div>
                  <div class="ml-preview-page_card-pin-code mb-4">
                    PIN-код: {{ sertPin }}
                  </div>
                </div>
                <ImageCanvas
                  :cardNumber="sertNumber"
                  :cardPinCode="sertPin"
                  :cardImage="sertImage"
                />
                <div class="col-12  d-flex flex-column align-items-center">
                  <a
                    v-if="saveToWallet.show"
                    :href="saveToWallet.url"
                    class="ml-silver-btn mb-4 ml-preview-page_preview-btn"
                  >
                    <img
                      class="mr-3"
                      src="@/assets/img/example/wallet.png"
                      alt=""
                    />
                    Сохранить в Wallet
                  </a>
                  <a
                    v-if="saveToGoogle.show"
                    :href="saveToGoogle.url"
                    class="ml-silver-btn mb-4 ml-preview-page_preview-btn"
                  >
                    <img
                      class="mr-3"
                      src="@/assets/img/google-pay.svg"
                      alt=""
                    />
                    Сохранить в Google Pay
                  </a>
                  <a
                    v-if="saveToGallery"
                    href="#"
                    @click.prevent="saveImage"
                    class="ml-silver-btn mb-4 ml-preview-page_preview-btn"
                  >
                    Сохранить как изображение
                  </a>
                  <a
                    v-if="saveToTg"
                    @click.prevent="openTelegramChannel"
                    target="_blank"
                    class="ml-silver-btn mb-4 ml-preview-page_preview-btn"
                  >
                    Отправить в Telegram
                  </a>
                  <a
                    v-if="sertSavePDF || isPreview"
                    :href="
                      (configOwner && configOwner.SaveOptions.DocumentUrl) ||
                        '#'
                    "
                    target="_blank"
                    class="ml-silver-btn mb-4 ml-preview-page_preview-btn"
                  >
                    Распечатать
                  </a>
                  <a
                    v-if="sertSavePDF || isPreview"
                    :href="
                      (configOwner && configOwner.SaveOptions.DocumentUrl) ||
                        '#'
                    "
                    target="_blank"
                    class="ml-silver-btn mb-4 ml-preview-page_preview-btn"
                  >
                    Cохранить
                  </a>
                  <a
                    v-if="saveToEmail || isPreview"
                    href="#"
                    class="ml-silver-btn mb-4 ml-preview-page_preview-btn"
                    @click.prevent="openEmailDialog"
                  >
                    Отправить на E-mail
                  </a>
                </div>
              </div>
              <div style="padding-top: 30px">
                <MlSlideBlock :width="544" class="mb-4">
                  <template #title>{{ sertRulesHeader }}</template>
                  <template #body>
                    <div class="how-use px-3 pt-6" style="border: none">
                      <div
                        class="item mb-2"
                        v-for="(item, index) in sertRulesSteps"
                        :key="index"
                      >
                        <div class="numb">{{ index + 1 }}</div>
                        <div
                          class="ml-text-14-24-500 ml-text-grey pl-1"
                          v-linkified:options="{ className: 'ml-link' }"
                        >
                          {{ item }}
                        </div>
                      </div>

                      <!-- <div class="item mb-2">
                        <div class="numb">1</div>
                        <div
                          class="ml-text-14-24-500 ml-text-grey pl-1"
                          v-linkified:options="{ className: 'ml-link' }"
                        >
                          Сохраните сертификат и <br />
                          PIN-код
                        </div>
                      </div>
                      <div class="item mb-2">
                        <div class="numb">2</div>
                        <div
                          class="ml-text-14-24-500 ml-text-grey pl-1"
                          v-linkified:options="{ className: 'ml-link' }"
                        >
                          Выберите магазин, в котором можно <br />
                          использовать сертификат
                        </div>
                      </div>
                      <div class="item mb-2">
                        <div class="numb">3</div>
                        <div
                          class="ml-text-14-24-500 ml-text-grey pl-1"
                          v-linkified:options="{ className: 'ml-link' }"
                        >
                          Сообщите или введите в магазине номер <br />
                          сертификата и PIN-код
                        </div>
                      </div>
                      <div class="item">
                        <div class="numb">4</div>
                        <div
                          class="ml-text-14-24-500 ml-text-grey pl-1"
                          v-linkified:options="{ className: 'ml-link' }"
                        >
                          Оплатите всю или часть покупки <br />
                          сертификатом
                        </div>
                      </div> -->
                      <div class="ml-divider my-4"></div>
                      <div>
                        <div class="ml-text-16-22-500 ml-text-grey mb-3">
                          Нужно больше информации
                        </div>
                        <a
                          :href="sertRulesUrl"
                          target="_blank"
                          style="width: 146px"
                          class="ml-silver-btn px-0  ml-text-grey1"
                        >
                          Узнать больше
                        </a>
                      </div>
                    </div>
                  </template>
                </MlSlideBlock>
              </div>
              <div>
                <MlSlideBlock :width="544" ref="faqblock">
                  <template #title>{{ sertFaqHeader }}</template>
                  <template #body>
                    <faq @resize="resizeFaq" :isInit="true" :isOwner="true" />
                    <div class="ml-divider mb-4"></div>
                    <div>
                      <div class="ml-text-16-22-500 ml-text-grey mb-3">
                        Не нашли ответ на вопрос
                      </div>
                      <a
                        :href="sertFaqUrl"
                        @click.prevent="openSupportDialog"
                        style="width: 146px"
                        class="ml-silver-btn px-0 ml-text-grey1"
                      >
                        Задать вопрос
                      </a>
                    </div>
                  </template>
                </MlSlideBlock>
              </div>
              <div class="d-flex justify-content-center my-10">
                <a
                  v-if="sertBotTg || isPreview"
                  @click.prevent="openBotTg"
                  target="_blank"
                  class="ml-social-btn mr-2"
                >
                  <img src="@/assets/img/default/social-icon-tg.svg" alt="" />
                </a>
                <a
                  v-if="sertBotWhatsapp || isPreview"
                  @click.prevent="openBotWhatsUp"
                  target="_blank"
                  class="ml-social-btn mr-2"
                >
                  <img
                    src="@/assets/img/default/social-icon-whatsapp.svg"
                    alt=""
                  />
                </a>
                <a
                  v-if="sertBotViber || isPreview"
                  @click.prevent="openBotViber"
                  target="_blank"
                  class="ml-social-btn"
                >
                  <img
                    src="@/assets/img/default/social-icon-viber.svg"
                    alt=""
                  />
                </a>
              </div>
              <div class="ml-text-13-18-500 ml-text-grey3 text-center">
                Совершая конклюдентные действия на данном сайте Вы <br />
                соглашаетесь с
                <!-- <a href="#" @click.prevent="docsDialog(2)">
                  <u>Политикой конфиденциальности</u>
                </a>
                и -->
                <a href="#" @click.prevent="docsDialog(1)">
                  <u>Офертой.</u>
                </a>
              </div>
            </div>
            <!-- <certificate-footer show-title /> -->
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import SwipeButton from '@/components/UI/SwipeButton'
import faq from '@/components/Burger/Pages/FAQ'
import { mapActions, mapGetters, mapState } from 'vuex'
import appTypes from '@/store/app/types'
import AuthService from '@/api/AuthService'
import faqTypes from '@/store/faq/types'
import ownerTypes from '@/store/owner/types'
import MlSlideBlock from '@/components/UI/default/MlSlideBlock'
import EmailDialog from '@/components/Modal/EmailDialog'
// import SuccessDialog from '@/components/Modal/SuccessDialog'
import BotDialog from '@/components/Modal/Owner/BotDialog'
import SupportDialog from '@/components/Modal/Owner/SupportDialog'
// import DocDialog from '@/components/Modal/Owner/DocDialog'
import Config from '@/config'
// import panelBurgerTypes from '@/store/panelBurger/types'
import MininDocDialog from '@/helpers/mixins/docDialog'
import ImageCanvas from '@/components/Owner/ImageCanvas'
import VueBarcode from 'vue-barcode'
import { format, parseISO } from 'date-fns'
import axios from 'axios'

const SELLER_CONFIG = {
  LOGO: 'logo',
  WEBSITE: 'website'
}

export default {
  mixins: [MininDocDialog],
  components: {
    SwipeButton,
    faq,
    MlSlideBlock,
    ImageCanvas,
    barcode: VueBarcode
  },
  data: () => ({
    isPreview: false,
    testingMode: false,
    show: false,
    price: 3000,
    congratulation: 'Здесь будет текст поздравления!'
  }),
  computed: {
    ...mapGetters('app', [
      'hasOrderPage',
      'hasOwnerPage',
      'hasOwnerAnimationPage'
    ]),
    ...mapState({
      config: state => state?.app?.config,
      configOwner: state => state?.owner?.pageOwnerConfig,
      sellerConfig: state => state?.app?.config?.SertSeller,
      preview: state => state.basket.preview
    }),
    sellerLogo() {
      // const result = this.sellerConfig?.find(
      //   x => x.Name?.toLowerCase() === SELLER_CONFIG.LOGO && x.Shown
      // )
      // return result?.Value

      // const favicon =
      //   document.querySelector("link[rel*='icon']") ||
      //   document.getElementById('favicon')
      // favicon.href = this.configOwner?.LogoHeader
      return (
        this.configOwner?.LogoHeader ||
        this.sellerConfig?.find(
          x => x.Name?.toLowerCase() === SELLER_CONFIG.LOGO && x.Shown
        )?.Value
      )
    },
    /** Текущая темя виджета */
    currentTheme() {
      const themeName = this.config?.theme ?? 'default'
      return `mloyalty-${themeName}-theme`
    },
    sertName() {
      return (
        this.configOwner?.SertCard?.Header ||
        `Cертификат на ${this.preview?.price}₽` ||
        '-'
      )
    },
    sertDate() {
      const configDate = this.configOwner?.SertCard?.ExpDate
      const date = configDate ? new Date(configDate) : new Date()
      return format(date, 'dd.MM.yyyy')
    },
    sertCongratulation() {
      return (
        this.configOwner?.SertCard?.Congratulation ||
        this.preview?.congratulation
      )
    },
    sertImage() {
      return this.configOwner?.SertCard?.Image || this.preview?.certificate?.img
    },
    sertNumber() {
      return this.configOwner?.SertCard?.Number || '10000007'
    },
    sertPin() {
      return this.configOwner?.SertCard?.SecretCode || '678 579'
    },
    saveToWallet() {
      return {
        show: this.configOwner?.SaveOptions?.Wallet === true,
        url: this.configOwner?.SaveOptions?.WalletUrl
      }
    },
    saveToGoogle() {
      return {
        show: this.configOwner?.SaveOptions?.GooglePay === true,
        url: this.configOwner?.SaveOptions?.GooglePayUrl
      }
    },
    saveToGallery() {
      return this.configOwner?.SaveOptions?.Galery === true
    },
    saveToEmail() {
      return this.configOwner?.SaveOptions?.Email === true
    },
    saveToTg() {
      return this.configOwner?.SaveOptions?.Telegram
    },
    sertRulesHeader() {
      return (
        this.configOwner?.SertRules?.Header ||
        'Как использовать, и где действует?'
      )
    },
    sertRulesSteps() {
      const result = []
      const target = this.configOwner?.SertRules?.Steps
      if (target) {
        Object.keys(target).forEach(key => result.push(target[key]))
      }

      if (this.isPreview) {
        return [
          'Сохраните сертификат и\nPIN-код',
          'Выберите магазин, в котором можно\nиспользовать сертификат',
          'Сообщите или введите в магазине номер\nсертификата и PIN-код',
          'Оплатите всю или часть покупки\nсертификатом'
        ]
      } else {
        return result
      }
    },
    sertRulesUrl() {
      return this.configOwner?.SertRules?.ButtonUrl
    },
    sertFaqHeader() {
      return this.configOwner?.SertFaq?.Header || 'Часто задаваемые вопросы'
    },
    sertFaqUrl() {
      return this.configOwner?.SertFaq?.ButtonUrl || '#'
    },
    sertBotTg() {
      return this.configOwner?.SertBotsUrl?.Telegram
    },
    sertBotVk() {
      return this.configOwner?.SertBotsUrl?.VK
    },
    sertBotViber() {
      return this.configOwner?.SertBotsUrl?.Viber
    },
    sertPrint() {
      return this.configOwner?.SaveOptions?.PrintAction
    },
    sertSavePDF() {
      return this.configOwner?.SaveOptions?.ButtonAction
    },
    sertBotWhatsapp() {
      return this.configOwner?.SertBotsUrl?.Whatsapp
    }
  },
  methods: {
    ...mapActions('app', [appTypes.SET_APP_CODE_ACTION_WITHOUT_CONFIGS]),
    ...mapActions('faq', [faqTypes.GET_FAQS]),
    ...mapActions('owner', [ownerTypes.GET_PAGE_OWNER_CONFIG_ACTION]),
    getMaxHeight(value) {
      return Number(value.replaceAll('px', ''))
    },
    resizeFaq(value) {
      const secondBlockBody = document.querySelectorAll(
        '.ml-slide-block_body'
      )[1]
      if (secondBlockBody) {
        secondBlockBody.style.maxHeight = `${this.getMaxHeight(
          secondBlockBody.style.maxHeight
        ) + value}px`
      }
    },
    openTelegramChannel() {
      window.open(this.sertBotTg, '_blank')
    },
    openBotTg() {
      if (this.isPreview) return
      this.openBotDialog('telegram', this.sertBotTg)
    },
    openBotWhatsUp() {
      if (this.isPreview) return
      this.openBotDialog('whatsapp', this.sertBotWhatsapp)
    },
    openBotViber() {
      if (this.isPreview) return
      this.openBotDialog('viber', this.sertBotViber)
    },
    saveImage() {
      var link = document.createElement('a')
      link.download = 'certificate.jpg'
      link.href = document
        .getElementById('ml-owner-drawing-card')
        .toDataURL('image/jpeg')
      link.click()
    },
    openSupportDialog() {
      if (this.isPreview) return
      if (this.sertFaqUrl) {
        window.open(this.sertFaqUrl, '_blank')
      } else {
        this.$modal.show(
          SupportDialog,
          {},
          {
            ...Config?.defaultModal,
            width: 384
          }
        )
      }
    },
    openBotDialog(type, botUrl = null) {
      this.$modal.show(
        BotDialog,
        {
          type,
          botUrl
        },
        {
          ...Config?.defaultModal,
          width: 384
        }
      )
    },
    openEmailDialog() {
      if (this.isPreview) return

      this.$modal.show(
        EmailDialog,
        null,
        {
          ...Config?.defaultModal,
          width: 384
        }
        // {
        //   closed: this.closedEmailDialog
        // }
      )
    },
    // closedEmailDialog(e) {
    //   this.$modal.show(
    //     SuccessDialog,
    //     { text: 'Сертификат отправлен на e-mail traceur-pk@yandex.ru' },
    //     {
    //       ...Config?.defaultModal,
    //       width: 384
    //     }
    //   )
    // },
    error(message) {
      console.log('owner hash error: ', message)
      this.$router.push({
        name: 'Error404',
        params: {
          message: `CERTIFICATE OWNER HASH: ${message}`
        }
      })
    },
    async setInitialize() {
      //   const hash = this.$route?.params?.hash
      //   // const hash = 'Получить'
      //   if (!hash) {
      //     this.error('Код приложения не задан')
      //     return
      //   }
      //   console.log('await AUTH')
      //   await AuthService.authManager()
      //   console.log('await set app code', hash)
      //   await this[appTypes.SET_APP_CODE_ACTION_WITHOUT_CONFIGS](hash)
      await this[ownerTypes.GET_PAGE_OWNER_CONFIG_ACTION](this.$route?.name)
      //   if (this.hasOrderPage) {
      //     await this.$router.push({ name: 'Order' })
      //   }
      //
      this[faqTypes.GET_FAQS]({
        isWidgetBurger: false,
        isWidgetPreview: false,
        isSertOwnerSite: true
      })
    },
    onActionConfirmed() {
      if (this.hasOwnerAnimationPage) {
        this.$refs.preview.classList.add('ml-perview-card-shrink')
        setTimeout(() => {
          this.show = true
          this.bounce()
        }, 300)
      } else {
        this.$refs.preview.classList.remove('ml-perview-card-shrink')
        this.show = true
      }
    },
    bounce() {
      setTimeout(() => {
        this.$refs.preview.classList.remove('ml-perview-card-shrink')
        this.$refs.confeti.classList.add('ml-confeti-bounce')
        setTimeout(() => {
          this.$refs.confeti.remove()
        }, 400)
      }, 100)
    },
    goBack() {
      this.$router.push('/')
    }
  },
  mounted() {
    if (this.$route.params.isPreview && this.preview) {
      this.isPreview = true
      return
    }

    this.setInitialize()
  }
}
</script>
