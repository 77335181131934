import types from './types'
import jwt_decode from 'jwt-decode'

export default {
  [types.SET_ACCESS_TOKEN](state, accessToken) {
    // console.log('SET ACCESS TOKEN')
    state.accessToken = accessToken
    if (accessToken) {
      state.decodeJwt = jwt_decode(accessToken)
    }
  },
  [types.SET_REFRESH_TOKEN](state, refreshToken) {
    // console.log('SET REFRESH TOKEN')
    state.refreshToken = refreshToken
  }
}
