import types from './types'
import { v4 as uuid } from 'uuid'
import { cloneDeep, find } from 'lodash'

export default {
  [types.UPDATE_BASKET_ITEM_RECIPIENT](
    state,
    { id, name, email, phone, time, date, channel }
  ) {
    console.log(
      'UPDATE_BASKET_ITEM_RECIPIENT',
      id,
      name,
      email,
      phone,
      time,
      date
    )
    const { basket } = state
    if (basket) {
      const findItem = basket.find(item => item?.id === id)
      if (findItem) {
        const index = basket.indexOf(findItem)
        if (index >= 0) {
          const newItem = cloneDeep(findItem)
          newItem.friend.name = name
          newItem.friend.email = email
          newItem.friend.phone = phone
          newItem.friend.channel = channel
          console.log('NEW ITEM', newItem)
          state?.basket.splice(index, 1, newItem)
        }
      }
    }
  },
  [types.UPDATE_BASKET_ITEM_DELIVERY_DATETIME](state, { id, date, time }) {
    const { basket } = state
    if (basket) {
      const findItem = basket.find(item => item?.id === id)
      if (findItem) {
        const index = basket.indexOf(findItem)
        if (index >= 0) {
          const newItem = cloneDeep(findItem)
          newItem.friend.date = date
          newItem.friend.time = time
          state?.basket.splice(index, 1, newItem)
        }
      }
    }
  },
  [types.SET_PREVIEW](state, item) {
    state.preview = item
  },
  /**
   * Добавляет сертификат в корзину
   * @param {Object} state
   * @param {Object} item - добавляемый объект корзины
   */
  [types.ADD_CERTIFICATE](state, { item, maxCount }) {
    if (item) {
      item.id = uuid()
    }
    if (!state.basket) {
      state.basket = [item]
    } else {
      const findTheSame = state.basket.find(
        cert =>
          cert.certificate.id === item.certificate.id &&
          cert.price === item.price &&
          cert.congratulation === item.congratulation
      )
      if (findTheSame) {
        const newCount = item.count + findTheSame.count
        if (newCount < maxCount) {
          item.count = newCount
          state.basket.splice(state.basket.indexOf(findTheSame), 1, item)
        }
      } else {
        state.basket.push(item)
      }
    }
  },
  /**
   * Изменение сертификата корзины
   * @param {Object} state
   * @param {Object} item - объект измененного сертификата
   */
  [types.UPDATE_CERTIFICATE](state, { item, maxCount }) {
    // console.log(item, maxCount)
    if (item.count === 0) {
      state.modalConfirmRemove.show = true
      state.modalConfirmRemove.item = item
    } else {
      let find = state.basket.find(x => x.id === item.id)
      if (find && find?.count < maxCount) {
        state.basket.splice(state.basket.indexOf(find), 1, item)
      }
    }
  },
  /**
   * Удаление сертификата из корзины
   * @param {Object} state
   */
  [types.REMOVE_CERTIFICATE](state) {
    const basketItem = state.modalConfirmRemove.item
    if (basketItem) {
      const find = state.basket.find(item => item.id === basketItem.id)
      if (find) {
        state.basket.splice(state.basket.indexOf(find), 1)
      }
    }
    state.modalConfirmRemove.show = false
    state.modalConfirmRemove.item = null
    state.currentCertificate = null
  },
  /**
   * Отмена удаления сертификата из корзины
   * @param {Object} state
   */
  [types.CANCEL_REMOVE_CERTIFICATE](state) {
    let basketItem = state.modalConfirmRemove.item
    basketItem.count = 1
    const find = state.basket.find(item => item.id === basketItem.id)
    if (find) {
      state.basket.splice(state.basket.indexOf(find), 1, basketItem)
    }
    state.modalConfirmRemove.show = false
    state.modalConfirmRemove.item = null
  },
  [types.SET_BASKET](state, basket) {
    state.basket = basket
  },
  [types.ADD_CARDLOYALITY](state, CardLoyalty){
    state.basket[0].CardLoyalty = CardLoyalty
  },
  [types.SET_CURRENT_CERTIFICATE](state, basketItem) {
    state.currentCertificate = basketItem
  },
  [types.CALL_CONFIRM_MODAL](state, basketItem) {
    state.modalConfirmRemove.show = true
    state.modalConfirmRemove.item = basketItem
  }
}
