import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import multiguard from 'vue-router-multiguard'
import routeGuard from './guards/routeGuard'
import sitecodeGuard from './guards/sitecodeGuard'
import authGuard from './guards/authGuard'
import configGuard from './guards/configGuard'
import orderGuard from './guards/orderGuard'
// import subscriptionGuard from './guards/subscriptionGuard'
import contactGuard from './guards/contactGuard'
import basketGuard from './guards/basketGuard'
import confirmGuard from './guards/confirmGuard'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Home',
  //   redirect: {
  //     name: 'Error404'
  //   }
  // },
  {
    path: '/',
    name: 'Order',
    component: () => import('../views/Order.vue'),
    beforeEnter: multiguard([sitecodeGuard, routeGuard, orderGuard])
  },
  // {
  //   path: '/subscription',
  //   name: 'Subscription',
  //   component: () => import('../views/Subscription.vue'),
  //   // beforeEnter: multiguard([routeGuard, subscriptionGuard])
  //   beforeEnter: multiguard([subscriptionGuard])
  // },
  {
    path: '/friend/contact',
    name: 'FriendContact',
    component: () => import('../views/Friend/Contact.vue'),
    meta: { noGuard: true }
  },
  {
    path: '/friend/delivery-time',
    name: 'FriendDeliveryTime',
    component: () => import('../views/Friend/DeliveryTime.vue'),
    meta: { noGuard: true }
  },
  {
    path: '/sending',
    name: 'Contacts',
    component: () => import('../views/Sending.vue'),
    beforeEnter: multiguard([routeGuard, contactGuard])
  },
  {
    path: '/confirming',
    name: 'Confirming',
    component: () => import('../views/Confirming.vue'),
    beforeEnter: multiguard([routeGuard, confirmGuard])
  },
  {
    path: '/basket',
    name: 'Basket',
    component: () => import('../views/Basket.vue'),
    beforeEnter: multiguard([routeGuard, basketGuard])
  },
  {
    path: '/preview-mobile',
    name: 'PreviewMobile',
    component: () => import('../views/PreviewMobile.vue'),
    beforeEnter: multiguard([routeGuard])
  },
  {
    path: '/yookassa',
    name: 'PaymentYookassa',
    component: () => import('../views/Yookassa/PaymentForm.vue'),
    beforeEnter: multiguard([routeGuard])
  },
  {
    path: '/yookassa-completion-payment',
    name: 'CompletionPaymentYookassa',
    component: () => import('../views/Yookassa/PaymentCompletion.vue'),
    meta: { noConfig: true }
  },
  {
    path: '/yookassa-payment-error',
    name: 'ErrorPaymentYookassa',
    component: () => import('../views/Yookassa/PaymentError.vue'),
    meta: { noGuard: true }
  },
  {
    path: '/payment-result',
    name: 'PaymentResult',
    component: () => import('../views/Yookassa/PaymentResult.vue'),
    meta: { noConfig: true }
  },
  {
    path: '/balance-verify',
    name: 'BalanceVerify',
    component: () => import('../views/Balance/BalanceVerify.vue'),
    beforeEnter: multiguard([routeGuard])
  },
  {
    path: '/balance',
    name: 'Balance',
    component: () => import('../views/Balance/Balance.vue'),
    beforeEnter: multiguard([routeGuard])
  },
  // {
  //   path: '/owner',
  //   name: 'CertificateOwner',
  //   component: () => import('../views/Owner.vue'),
  //   meta: { noGuard: true }
  //   // beforeEnter: multiguard([routeGuard])
  // },
  {
    path: '/owner',
    name: 'CertificateOwnerHash',
    component: () => import('../views/OwnerHash.vue'),
    meta: { noGuard: true },
    props: true,
    beforeEnter: multiguard([sitecodeGuard, routeGuard])
  },
  {
    path: '/:hash',
    name: 'SitecodeWatcher',
    meta: { noGuard: true },
    component: () => import('../views/SitecodeWatcher.vue'),
    beforeEnter: multiguard([routeGuard])
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    meta: { noGuard: true },
    component: () => import('../views/Error/AccessDenied.vue')
  },
  {
    path: '/error',
    name: 'Error',
    meta: { noGuard: true },
    component: () => import('../views/Error/Error.vue')
  },
  {
    path: '/error/404',
    name: 'Error404',
    meta: { noGuard: true },
    component: () => import('../views/Error/404.vue')
  }
]

const router = new VueRouter({
  mode: 'abstract',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(
  multiguard([
    authGuard,
    configGuard,
    (to, from, next) => {
      //При перехоадах сбрасываем отсутп от низ для кнопок бургера и корзины
      store.commit('app/SET_BOTTOM_OFFSET', 0)
      store.commit('app/SET_OPACITY', null)

      // This goes through the matched routes from last to first, finding the closest route with a title.
      // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
      const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find(r => r.meta && r.meta.title)
      if (nearestWithTitle) document.title = nearestWithTitle.meta.title
      next()
    }
  ])
)

export default router
