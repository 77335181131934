import store from '../store'
import config from '@/config'

export default function(Vue) {
  Vue.auth = {
    /**
     * Возвращает accessToken либо из localStorage, либо из store.
     */
    getAccessToken() {
      const useLS = config?.useLS
      let token = null

      if (useLS) {
        token = localStorage.getItem('access_token')
      } else {
        token = store?.state?.auth?.accessToken
      }
      return token
    },
    /**
     * Возвращает refreshToken либо из localStorage, либо из store.
     */
    getRefreshToken() {
      const useLS = config?.useLS
      let token = null

      if (useLS) {
        token = localStorage.getItem('refresh_token')
      } else {
        token = store?.state?.auth?.refreshToken
      }
      return token
    },
    /**
     * Сохраняет accessToken в localStorage.
     */
    setAccessToken(token) {
      const useLS = config?.useLS
      if (useLS) {
        localStorage.setItem('access_token', token)
      }
    },
    /**
     * Сохраняет refreshToken в localStorage.
     */
    setRefreshToken(token) {
      const useLS = config?.useLS
      if (useLS) {
        localStorage.setItem('refresh_token', token)
      }
    },
    /**
     * Удаляет accessToken и refreshToken из localStorage.
     */
    logout() {
      const useLS = config?.useLS
      if (useLS) {
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
      }
    }
  }
  Object.defineProperties(Vue.prototype, {
    $auth: {
      get() {
        return Vue.auth
      }
    }
  })
}
