<template>
  <v-btn
    fab
    small
    width="48"
    height="48"
    class="mloyalty-circle-basket"
    elevation="0"
    @click.prevent="openShopCart"
  >
    <img
      class="shop-cart-icon"
      src="~@/assets/img/default/shopping-cart.svg"
      alt=""
    />
    <span class="shop-cart-count">{{ allPositions.count }}</span>
  </v-btn>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import appTypes from '@/store/app/types'

export default {
  computed: {
    ...mapGetters('basket', ['allPositions'])
  },
  methods: {
    ...mapMutations('app', [appTypes.SET_OPACITY]),
    openShopCart() {
      this[appTypes.SET_OPACITY](0)
      setTimeout(() => {
        this.$router.push('/basket')
      }, 250)
    }
  }
}
</script>
