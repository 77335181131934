import types from './types'

export default {
  [types.SET_ORDER]: (state, order) => {
    delete order.Message
    delete order.ErrorCode
    state.order = order
  },
  [types.SET_FRIEND]: (state, friend) => {
    if (!friend) {
      state.friend.date = null
      state.friend.time = null
      state.friend.phone = null
      state.friend.name = null
      state.friend.email = null
      state.friend.channel = null
    } else {
      state.friend = friend
    }
  },
  [types.SET_FRIEND_DELIVERY_DATETIME]: (state, { date, time }) => {
    state.friend.date = date
    state.friend.time = time
  },
  [types.SET_FRIEND_RECIPIENT]: (
    state,
    { name, phone, email, channel, friendId }
  ) => {
    state.friend.name = name
    state.friend.phone = phone
    state.friend.email = email
    state.friend.channel = channel
    state.friend.friendId = friendId
  },
  [types.SET_SUBSCRIPTION_PAGE_CONFIG]: (state, payload) => {
    state.pageSubscriptionConfig = payload
  }
}
