<template>
  <v-app>
    <v-main :class="currentTheme">
      <div v-if="loading" class="global-loading">
        <div class="pb flex-grow-1 d-flex justify-center align-center">
          <MlLoading />
        </div>
      </div>
      <div class="h100 ml-preview-page">
        <div class="h100 ml-preview-container">
          <div class="h100 mloyalty-panel-body">
            <router-view></router-view>
          </div>
          <!-- Кнопка бургера -->
          <burger-btn
            id="burger-btn"
            v-if="showBtnBurger"
            ref="burger-btn"
            :style="{
              bottom: paddingBottomForBasketAndBurger
            }"
          />
          <basket-btn
            ref="basket-btn"
            v-if="showBtnBasket"
            :style="{
              bottom: paddingBottomForBasketAndBurger,
              right: rightPosForBasketBtn
            }"
          />
          <modal-confirm-remove-certificate v-if="modalConfirmRemove" />
        </div>
      </div>
      <v-navigation-drawer
        class="elevation-0 mloyalty-burger-panel"
        hide-overlay
        :width="config.panelWidth"
        :value="showPanelBurger"
        stateless
        temporary
        right
        app
      >
        <burger-layout />
      </v-navigation-drawer>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import panelTypes from '../../store/panel/types'
import MlLoading from '@/components/UI/MlLoading'
import ModalConfirmRemoveCertificate from '@/components/Panel/ModalConfirm'
import burgerLayout from '@/components/Burger/_Layout'
import BasketBtn from '@/components/BasketBtn'
import BurgerBtn from '@/components/BurgerBtn'
export default {
  components: {
    MlLoading,
    ModalConfirmRemoveCertificate,
    burgerLayout,
    BasketBtn,
    BurgerBtn
  },
  data: () => ({
    btnInit: false
  }),
  computed: {
    ...mapState({
      loading: state => state.app.loading,
      modalConfirmRemove: state => state.basket.modalConfirmRemove.show,
      showPanelBurger: state => state.panelBurger.show,
      config: state => state.app.config,
      offsetBottom: state => state.app.offsetBottom,
      opacity: state => state.app.opacity,
      burgerShown: state => {
        return {
          order: state?.app?.pageOrderConfig?.BurgerShown ?? true,
          contact: state?.app?.pageContactConfig?.BurgerShown ?? true,
          basket: state?.app?.pageBasketConfig?.BurgerShown ?? true,
          paymentResult:
            state?.app?.pagePaymentStatusConfig?.BurgerShown ?? true
        }
      }
    }),
    /** Текущая темя виджета */
    currentTheme() {
      const themeName = this.config?.theme ?? 'default'
      return `mloyalty-${themeName}-theme`
    },
    /**Смещение для кнопки корзины если страницы ввода контактов, т.к. кнопку бургера скрываем */
    isSendingPage() {
      return this.$route.path === '/sending'
    },
    isHomePage() {
      return this.$route.path === '/'
    },
    rightPosForBasketBtn() {
      return this.showBtnBurger === true ? '92px' : '34px'
    },
    paddingBottomForBasketAndBurger() {
      const defaultValue = 18
      // let offset = defaultValue
      // if (this.offsetBottom > 0) {
      const offset = defaultValue + Number(this.offsetBottom) - 10
      // }
      // if (this.$route.path === '/' || this.$route.path === '/sending')
      //   value = 94
      // if (this.$route.path === '/confirming') value = 125
      // this.changeOpacityBtns()
      return `${offset}px`
    },
    showBtnBasket() {
      let isShow = true
      if (
        this.$route.path === '/basket' ||
        this.$route.path === '/payment-result' ||
        this.$route.path === '/preview-mobile' ||
        this.$route.path === '/balance-verify' ||
        this.$route.path === '/owner' ||
        this.$route.path.includes('yookassa') ||
        this.$route.path.includes('/friend/')
      )
        isShow = false
      return isShow
    },
    showBtnBurger() {
      const currentRoute = this.$route.path
      // console.log('CURENT ROUT', currentRoute)
      let isShow = true
      switch (currentRoute) {
        case '/':
          isShow = this.burgerShown?.order ?? false
          break
        case '/sending':
          isShow = this.burgerShown?.contact ?? false
          break
        case '/basket':
          isShow = this.burgerShown?.basket ?? false
          break
        case '/payment-result':
          isShow = this.burgerShown?.paymentResult ?? false
          break
        case '/yookassa-completion-payment/':
          isShow = false
          break
        default:
          if (
            this.$route.path === '/basket' ||
            this.$route.path === '/sending' ||
            this.$route.path === '/preview-mobile' ||
            this.$route.path === '/owner' ||
            this.$route.path.includes('balance') ||
            this.$route.path.includes('/friend/')
          ) {
            isShow = false
          }
          break
      }
      return isShow
    }
  },
  watch: {
    showPanelBurger(value) {
      window?.xprops?.onHide()
      if (value === true) {
        const config = this.$store?.state?.panelBurger?.pageBurger
        if (!config) {
          this.$store.dispatch('panelBurger/GET_SITE_BURGER_ACTION')
        }
      }
    },
    opacity(newValue) {
      this.showNavs(newValue)
      // if (newValue === 0) {
      // this.$refs['burger-btn'].$el.style.opacity = 0
      // this.$refs['basket-btn'].$el.style.opacity = 0
      // } else {
      // this.showNavs()
      // }
    }
  },
  methods: {
    ...mapMutations('panel', [
      panelTypes.CURRENT_PAGE_SET,
      panelTypes.TOGGLE_PANEL
    ]),
    showNavs(isShow = 1) {
      const basket = this.$refs['basket-btn']?.$el?.style
      const burger = this.$refs['burger-btn']?.$el?.style
      // console.log('basket', basket)
      // console.log('burger', burger)
      if (burger) {
        this.$refs['burger-btn'].$el.style.opacity = isShow
      }
      if (basket) {
        this.$refs['basket-btn'].$el.style.opacity = isShow
      }
    }
    // changeOpacityBtns() {
    //   setTimeout(() => {
    //     const basket = this.$refs['basket-btn']?.$el?.style
    //     const burger = this.$refs['burger-btn']?.$el?.style
    //     if (burger) {
    //       burger.opacity = 1
    //     }
    //     if (basket) {
    //       basket.opacity = 1
    //     }
    //     // console.log(this.$refs['burger-btn'].$el.style)
    //     // this.$refs['burger-btn'].$el.classList.remove('opactity-none')
    //     // this.$refs['basket-btn'].$el.classList.remove('opactity-none')
    //     // this.btnInit = true
    //   }, 1000)
    //   // }
    // }
  },
  mounted() {
    if (this.$confetti) {
      this.$confetti.start({
        particles: [
          {
            type: 'heart',
            colors: ['red', 'pink']
          }
          // {
          //   type: 'circle',
          //   colors: ['SlateBlue', 'OliveDrab']
          // },
          // {
          //   type: 'rect',
          //   colors: ['violet']
          // }
        ],
        defaultDropRate: 2,
        defaultSize: 9,
        particlesPerFrame: 0.08
      })
    }
  }
}
</script>
