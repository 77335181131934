<template>
  <div class="wrapper">
    <header class="header">
      <button class="ml-text-14-20-600" @click="closeModal">Закрыть</button>
    </header>
    <main class="body ml-text-14-20">
      <div>
        {{ title }}
      </div>
      <br />
      <div>
        {{ text }}
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: 'MlAgreement',
  props: {
    text: {
      type: String
    },
    title: {
      type: String
    }
  },
  methods: {
    closeModal() {
      this.$FModal.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  .header {
    padding: 15px;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #d6d6d6;
  }

  .body {
    padding: 15px;
    white-space: pre-line;
  }
}
</style>
