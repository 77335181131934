<template>
  <!-- <router-view /> -->
  <div class="mvh100">
    <modals-container />
    <component :is="componentLayout"></component>
  </div>
</template>

<script>
import Layout from './components/Layout/Default'
import Owner from './views/OwnerHash'
import appTypes from '@/store/app/types'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  components: {
    Layout,
    Owner
  },
  computed: {
    ...mapState({
      code: state => state?.app?.code,
      isDesktop: state => state?.app?.isDesktop,
      isMobile: state => state?.app?.isMobile
    }),
    componentLayout() {
      if (
        this.$route?.path === '/owner' ||
        this.$route?.name === 'CertificateOwnerHash'
      )
        return 'Owner'
      return 'Layout'
    },
    inWidget() {
      return this.isDesktop && !this.isMobile
    }
  },
  methods: {
    ...mapActions('app', [appTypes.SET_APP_CODE_ACTION]),
    ...mapMutations('app', [
      appTypes.SET_APP_HOSTNAME,
      appTypes.SET_APP_PROTOCOL,
      appTypes.SET_APP_PATHNAME,
      appTypes.SET_TYPE_BROWSER
    ])
  },
  mounted() {
    const hostname = window?.xprops?.hostname
    const protocol = window?.xprops?.protocol
    const pathname = window?.xprops?.pathname
    if (hostname) {
      this[appTypes.SET_APP_HOSTNAME](hostname)
    }
    if (protocol) {
      this[appTypes.SET_APP_PROTOCOL](protocol)
    }
    if (pathname) {
      this[appTypes.SET_APP_PATHNAME](pathname)
    }
    const isMobile = this.$helper?.isMobile()
    window?.xprops?.onProps(async newProps => {
      console.log('onProps event', newProps)
      const code = newProps?.code
      const isClosed = newProps?.isClosed
      if (code && code !== this.code && code !== '*') {
        await this[appTypes.SET_APP_CODE_ACTION]({
          code: code,
          toMain: true
        })
      }
    })
    // console.log('IS MOBILE', isMobile)
    isMobile && this[appTypes.SET_TYPE_BROWSER](isMobile)

    // await AuthService.authManager()
    // this.getSiteConfig()
  }
}
</script>
