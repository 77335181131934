<template>
  <div class="ml-slide-block m-auto" :style="widthBlock">
    <div class="ml-slide-block_title " ref="title" @click="toggle">
      <img
        class="ml-slide-arrow_open"
        src="@/assets/img/arrow-down.svg"
        alt=""
      />
      <img
        class="ml-slide-arrow_close"
        src="@/assets/img/arrow-up.svg"
        alt=""
      />
      <slot name="title"></slot>
    </div>
    <div class="ml-slide-block_body" ref="body">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
      default: null
    }
  },
  computed: {
    widthBlock() {
      let width
      if (this.width) {
        width = `${this.width}px`
      } else {
        width = '100%'
      }
      return {
        'max-width': width
      }
    }
  },
  methods: {
    toggle() {
      let elFaq = this.$refs['title']
      elFaq.classList.toggle('active')
      let el = this.$refs['body']
      if (el.style.maxHeight) {
        el.style.maxHeight = null
        el.style.paddingTop = '0px'
      } else {
        el.style.maxHeight = el.scrollHeight + 10 + 'px'
        el.style.paddingTop = '10px'
      }
    }
  }
}
</script>
