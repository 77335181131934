export default {
  CREATE_CERT_OWNER_ACTION: 'CREATE_CERT_OWNER_ACTION',
  VERIFY_CERT_CARD_AND_PIN_ACTION: 'VERIFY_CERT_CARD_AND_PIN_ACTION',
  CREATE_OR_UPDATE_OWNER_ACTION: 'CREATE_OR_UPDATE_OWNER_ACTION',
  CREATE_OR_UPDATE_FRINED_ACTION: 'CREATE_OR_UPDATE_FRINED_ACTION',
  GET_PAGE_OWNER_CONFIG_ACTION: 'GET_PAGE_OWNER_CONFIG_ACTION',
  SEND_EMAIL_CERTIFICATE_ACTION: 'SEND_EMAIL_CERTIFICATE_ACTION',
  GET_SERT_CARD_INFO_ACTION: 'GET_SERT_CARD_INFO_ACTION',

  SET_CERT_OWNER: 'SET_CERT_OWNER',
  SET_BALANCE_IDENTITY: 'SET_BALANCE_IDENTITY',
  SET_PAGE_OWNER_CONFIG: 'SET_PAGE_OWNER_CONFIG'
}
